import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";

class Form extends Component {
  renderError({ error, touched }) {
    if (error && touched) {
      return (
        <div className="custom-error-input" style={{ color: 'red' }}>{error}</div>
      );
    }
  }

  renderInput = ({ input, label, meta, type }) => {
    const errorClass = `field ${meta.error && meta.touched ? "error" : ""}`;

    return (
      <div>
        <div className={`age-confrim-checkbox ${errorClass}`}>
          <div class="ui checkbox">
            <input  {...input} type={type} required/>
            <label>{label}</label>
          </div>
        </div>

        {this.renderError(meta)}
      </div>
    );
  };

  onSubmit = (formValues) => {
    this.props.onAgeConfirm(formValues)
  }

  render() {
  
    return (
      <div>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)} >
          <Field
            name="verified"
            component={this.renderInput}
            label="I am of legal drinking age in my country of residence."
            type="checkbox"
          />
        
          <button className="btn purple" style={{marginTop: '40px'}} >Submit</button>
        </form>
      </div>
    );
  }
}

const validate = formValues => {
  const errors = {};

  if (!formValues.verified) {
    // on run if the user did not enter a title
    errors.verified = "Please check this box if you want to proceed.";
  }

  return errors;
};

export default reduxForm({
  form: "streamCreate",
  validate: validate
})(Form);
