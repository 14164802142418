import "./FatInspiration.scss";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Gallery from "../gallery/Gallery";
import Banner from "../slider/Banner";
import Content from "../content/Content";

import Recipes from "../recipes/Recipes";

class FatInspiration extends Component {
  renderContent() {
    return (
      <div className="fat-inspiration">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`FAT bastard wines | FAT Inspirations `}</title>
          <meta
            name="description"
            content="The only thing equal to drinking a glass of FAT bastard wine is indulging in a delicious meal paired perfectly with FAT bastard wines."
          />
          <link rel="canonical" href={window.location} />
        </Helmet>

        <Banner
          title="FAT <i>Inspirations</i>"
          breadcrumbs="HOME / FAT INSPIRATIONS"
          subtitle={`
          <p>The only thing equal to drinking a glass of <span style="color: rgb(214, 176, 82);">FAT <em>bastard</em></span> wine is indulging in a delicious meal paired perfectly with <span style="color: rgb(214, 176, 82);">FAT <em>bastard </em></span>wines.</p>
        `}
        />

        <div className="ui container">
          <Content
            disableFlex
            fullWidth
            centered
            animated
            item={{
              title: "UNIQUE CULINARY CREATIONS",
              subtitle: ""
            }}
          />

          <Recipes showHeader="false" />
        </div>
        <Gallery pageId={4} />

        <div className="ui container">
          <Content
            disableFlex
            fullWidth
            centered
            animated
            item={{
              title: "LIVE A COLOURFUL LIFE",
              subtitle: ""
            }}
          />

          <div className="poem">
            <p>
              Live a colourful life <br />
              Paint the grey into{" "}
              <span>
                <i>gold</i>
              </span>
            </p>
            <p>
              Live a colourful life <br />
              Be{" "}
              <span>
                <i>brave</i>
              </span>
              . Be{" "}
              <span>
                <i>bold</i>
              </span>
            </p>
            <p>
              Live a colourful life <br />A life{" "}
              <span>
                <i>outside</i>
              </span>{" "}
              the ordinary
            </p>

            <p>
              Live a colourful life <br />
              Step towards the{" "}
              <span>
                <i>extraordinary</i>
              </span>
            </p>
            <p>
              Live a colourful life <br />
              Have the courage to{" "}
              <span>
                <i>believe</i>
              </span>
            </p>
            <p>
              Live a colourful life <br />
              Wear your{" "}
              <span>
                <i>big heart</i>
              </span>{" "}
              on your sleeve
            </p>
            <p>
              Live a colourful life <br />A life{" "}
              <span>
                <i>rich deep</i>
              </span>{" "}
              and true
            </p>
            <p> Live a colourful life </p>
            <p>
              Live it{" "}
              <span>
                <i>large</i>
              </span>{" "}
              and
              <br />
              just be you.
            </p>
          </div>
          <img
            className="bottle-footer"
            src="../../resources/img/Footer-Bottles.png"
            alt=""
          />
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.isVerified) {
      return <div>loading</div>;
    }

    return <div>{this.renderContent()}</div>;
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified
  };
};

export default connect(mapStateToProps)(FatInspiration);
