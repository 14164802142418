import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => (
  <div className="ui container" style={{ padding: "160px" }}>
    <img
      src="../../resources/img/Fat Bastard@2x.png"
      alt=""
      style={{
        marginTop: "120px",
        maxWidth: 200,
        display: "block",
        margin: "auto",
        position: "relative"
      }}
    />
    <center>
      <p style={{ marginTop: "20px" }}>Sorry, we couldnt find that page.</p>
      <Link to="/">Return to Home Page</Link>
    </center>
  </div>
);
export default NotFound;
