import { 
  CREATE_ENTRY,
  CREATE_ENTRY_FAILED
} from "../actions/types"

export default (state = {}, action) => {
  switch (action.type) {
    case CREATE_ENTRY:
      return {
        ...state,
        success: true,
        data: null
      }
    case CREATE_ENTRY_FAILED:
      return {
        ...state,
        success: null,
        data: action.payload.data
      }
    default:
      return state
  }
};
