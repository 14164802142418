import "./RecipeDetail.scss";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { fetchRecipe } from "../../actions";
import ReactHtmlParser from "react-html-parser";
import Banner from "../slider/Banner";
import Button from "../buttons/Button";
import Gallery from "../gallery/Gallery";
import WineItem from "../wines/WineItem";
import Loader from "../loader/Loader";
import Video from "../video/Video";
import { FAT_BASTARD_API_IMAGE_PATH } from '../../utils/paths';

class RecipeDetail extends Component {
  componentDidMount() {
    this.props.fetchRecipe(this.props.match.params.id);
  }

  renderTitle(title) {
    return title[0].map((item, index) => {
      return <p key={index}>{item}</p>;
    });
  }
  // switches image for video if video is present

  renderMedia = () => {
    if (this.props.recipe.video) {
      return <Video url={this.props.recipe.video} />;
    }

    return (
      <img
        src={`${FAT_BASTARD_API_IMAGE_PATH}${this.props.recipe.image}`}
        alt=""
      />
    );
  };

  renderImageGallery() {
    if (this.props.recipe.images) {
      const images = JSON.parse(this.props.recipe.images);

      return images.map((img, index) => {
        return (
          <div key={index}>
            <img
              src={`${FAT_BASTARD_API_IMAGE_PATH}${img}`}
              alt=""
            />
          </div>
        );
      });
    }
  }

  renderDownloadLink() {
    if (this.props.recipe.pdf) {
      const pdf = JSON.parse(this.props.recipe.pdf);
      const formattedPdf = pdf[0].download_link;
      return (
        <div>
          <a
            className="btn gold"
            target="_blank"
             rel="noopener noreferrer"
            href={`${FAT_BASTARD_API_IMAGE_PATH}${formattedPdf}`}
          >
            DOWNLOAD RECIPE
          </a>
        </div>
      );
    }
  }

  renderWine() {
    if (this.props.recipe.wine) {
      return <WineItem wine={this.props.recipe.wine} />;
    }
  }

  renderExtraContent(recipe) {
    if (recipe.extra_content !== null) {
      return (
        <div className="content">{ReactHtmlParser(recipe.extra_content)}</div>
      );
    }
  }

  renderRecipe = recipe => {
    if (!this.props.recipe) {
      return <Loader />;
    }

    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`FAT bastard wines | FAT Inspirations | ${recipe.meta_name}`}</title>
          <meta name="description" content={recipe.meta_description} />
          <link rel="canonical" href={window.location} />
        </Helmet>

        <div className="ui container">
          <div className="restaurant-container">
            <div className="restarurant-box _recipe-overview">
              <h2 className="title">
                {ReactHtmlParser(recipe.name)}
              </h2>
              <div className="inline-button-group">
                {this.renderDownloadLink()}
                <Button
                  color="purple"
                  toUrl="/fat-inspirations"
                  title="RETURN"
                />
              </div>
              <div className="restaurant_description">
                {ReactHtmlParser(recipe.description)}
              </div>
              
            </div>

            <div className="restarurant-box">{this.renderMedia()}</div>
          </div>

          <div className="content">{ReactHtmlParser(recipe.content)}</div>
          {this.props.recipe.images && <div className="restaurant-gallery">{this.renderImageGallery()}</div>}
          {this.renderExtraContent(recipe)}
          {this.renderWine()}
        </div>
      </React.Fragment>
    );
  };

  render() {
    if (!this.props.isVerified) {
      return <div>loading</div>;
    }

    return (
      <div className="restaurant-detail">
        {/* <div style={{ height: "320px" }}> */}
        <Banner
          title="FAT <i>Inspiration</i>"
          breadcrumbs="HOME / FAT INSPIRATION"
          subtitle={`
           <p>The only thing equal to drinking a glass of <span style="color: rgb(214, 176, 82);">FAT <em>bastard</em></span> wine is indulging in a delicious meal paired perfectly with <span style="color: rgb(214, 176, 82);">FAT <em>bastard </em></span>wines.</p>
          `}
        />
        {/* </div> */}
        {this.renderRecipe(this.props.recipe)}
        <Gallery pageId={4} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    recipe: state.recipes[ownProps.match.params.id],
    isVerified: state.isVerified
  };
};

export default connect(mapStateToProps, {
  fetchRecipe
})(RecipeDetail);
