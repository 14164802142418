import "./ContactUs.scss";
import React from "react";
import { Helmet } from "react-helmet";
import Banner from "../slider/Banner";
import ContactMain from "../forms/ContactMain";
import Gallery from "../gallery/Gallery";

export default function ContactUs() {
  return (
    <div className="contact-us">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`FAT bastard wines | Contact Us `}</title>
        <meta
          name="description"
          content="No one loves a GET TOGETHER more than us and we’d love it if we could grow our circle of friends by adding you. As they say, the more, the merrier. Cheers!"
        />
        <link rel="canonical" href={window.location} />
      </Helmet>

      <Banner
        title="GET IN TOUCH"
        breadcrumbs="HOME / GET IN TOUCH"
        subtitle={`
            <p>No one loves a <span style="color: rgb(214, 176, 82);">GET TOGETHER</span> more than us and we’d love it if we could grow our circle of friends by adding you. As they say, the more, the merrier. <span style="color: rgb(214, 176, 82);">Cheers!</span></p>
          `}
      />
      <div className="form-container">
        <ContactMain />
      </div>
      <br />
      <div className="container">
        <hr />

        <div className="contact_links">
          <p>
            This is the South African FAT{" "}
            <strong>
              <i>bastard</i>
            </strong>{" "}
            Website,
          </p>
          <p>
            To view the FAT{" "}
            <strong>
              <i>bastard</i>
            </strong>{" "}
            Website for Europe and Asia click{" "}
            <a
              target="blank"
              style={{ color: "#D6B052" }}
              href="http://international.fatbastard.com/"
            >
              here
            </a>
            .
          </p>
          <p>
            {" "}
            To view the FAT{" "}
            <strong>
              <i>bastard</i>
            </strong>{" "}
            Website for the US and Canada click{" "}
            <a
              target="blank"
              style={{ color: "#D6B052" }}
              href="http://fatbastard.com/?country=us"
            >
              here
            </a>
            .
          </p>
        </div>
      </div>
      <Gallery pageId={6} />
    </div>
  );
}
