import { FETCH_CITIES } from "../actions/types";
import _ from 'lodash';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_CITIES:
      return {..._.mapKeys(action.payload, 'id')};

    default:
      return state;
  }
};
