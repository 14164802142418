import React, { Component } from 'react'
import Button from '../buttons/Button'
import ReactHtmlParser from "react-html-parser";
import { FAT_BASTARD_API_IMAGE_PATH } from '../../utils/paths';

class WineItem extends Component {

  render(){
    const wine = this.props.wine;

    return (
      <div className="wine-item">
        <div className="wine-left">
          <img src={`${FAT_BASTARD_API_IMAGE_PATH}${wine.image_path}`} alt=""/>
        </div>
        
        <div className="wine-right">
          <h3 className={wine.theme}>{wine.title}</h3>
          <div className="wine-devider"></div>
          <div className="wine-item-subtitle">{ReactHtmlParser(wine.subtitle)}</div>
          
          <Button color={wine.theme} toUrl={`/the-fat-bastards/${wine.slug}`} title="READ MORE" />
        </div>
      </div>
    )
  }
}

export default WineItem