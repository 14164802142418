import "./Button.scss";
import React from "react";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

export default function ButtonArrow(props) {
  return (
    <div>
      <Link to={props.toUrl} className={`btnArrow ${props.color}`}>
        <FontAwesomeIcon icon={faArrowRight} />
      </Link>
    </div>
  );
}
