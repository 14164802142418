import _ from 'lodash'
import "./Restaurant.scss";
import React, { Component } from 'react';
import RestaurantItem from './RestaurantItem'
import Content from '../content/Content'
import Loader from '../loader/Loader'

class RestaurantList extends Component {
  renderRestaurants() {
    
  
    return this.props.restaurants.map(restaurant => {
      if(restaurant !== null){
        return <RestaurantItem key={restaurant.id} restaurant={restaurant}/>
      } else {
        return <></>
      }
    })
  }

  renderHeader(){
    if(this.props.showHeader === true){
      return <Content disableFlex fullWidth centered item={{ 
        title: "FAT INSPIRATION", 
        subtitle: 'The only thing equal to drinking a glass of <span style="color: #D6B052">FAT <i>bastard</i></span> wine is indulging in a delicious meal paired perfectly with <span style="color: #D6B052">FAT <i>bastard</i></span> wines.' }} />
    } 
  }
  
  render() {
    if(_.isEmpty(this.props.restaurants)){
      return <Loader />
    }
    
    return (  
      <div>
        {this.renderHeader()}
        <div className="recipes">
          {this.renderRestaurants()}
        </div>
      </div>
    )
  }
}


export default RestaurantList

