import React from "react";
import SocialIconList from "../social-icons/SocialIconList";

export default function SocialCardFooter(props) {
  let profile_picture = "";
  let type = "";
  let brandUrl = "";
  let articleUrl = "";

  if (props.article.attachments.instagram_item) {
    type = "Instagram";
    brandUrl = "https://www.instagram.com/fatbastardsa/";
    articleUrl = props.article.attachments.instagram_item.link;
    profile_picture = props.article.attachments.instagram_item.profile_picture;
  }

  if (props.article.attachments.facebook_post) {
    type = "Facebook";
    brandUrl = "https://web.facebook.com/FATbastardWineSA?_rdc=1&_rdr";
    articleUrl = props.article.attachments.facebook_post.link;
    profile_picture =
      props.article.attachments.facebook_post.profile_picture_url;
  }

  return (
    <div className="social-footer">
      <div className="social-card-avatar">
        <div className="social-card-avatar-image">
          <a href={articleUrl} rel="noopener noreferrer" target="_blank">
            <img src={profile_picture} alt="" />
          </a>
        </div>

        <div className="social-card-avatar-links">
          <a
            className="instagram-home"
            href={brandUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            {" "}
            Fat Bastard Wine SA
          </a>{" "}
          <br />
          <a
            className="instagram-post"
            href={articleUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            {" "}
            {type}
          </a>
        </div>
      </div>

      <div className="social-card-links">
        <SocialIconList />
      </div>
    </div>
  );
}
