import React from 'react'

export default function FooterList(props) {
  
  return (
    <React.Fragment>
      <ul className={`footer_list ${props.padding}`}>
        {props.children}
      </ul>
    </React.Fragment>
  )
}
