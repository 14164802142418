import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import RestaurantList from "./RestaurantList";
import { fetchRestaurants } from "../../actions";

class Restaurants extends Component {
  componentDidMount() {
    this.props.fetchRestaurants();
  }

  render() {
    console.log(this.props.restaurants)
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`FAT bastard wines | VIB | Restaurants `}</title>
          <meta
            name="description"
            content="To LIVE LARGE, you need to gallop to your local watering hole and take a chunk-size bite out of life. We unreservedly encourage you to try one of our VIB restaurants."
          />
          <link rel="canonical" href={window.location} />
        </Helmet>
        <RestaurantList
          restaurants={this.props.restaurants}
          showHeader={this.props.showHeader}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    restaurants: Object.values(state.restaurants)
  };
};

export default connect(
  mapStateToProps,
  {
    fetchRestaurants
  }
)(Restaurants);
