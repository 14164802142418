import React from 'react'
import './Covid19.scss'
import image from '../../resources/img/main.png'

const Covid19 = props => {

    return(
        <div className={props.className}>
            <div style={{margin:'10px'}}>
                <img src={image} alt="" width="250" />
            </div>
            <div>
                <p style={{marginBottom: '0'}}>For more information on COVID-19, visit <a target="_blank" rel="noopener noreferrer" style={{color:'#D6B052'}} href="https://sacoronavirus.co.za/">www.sacoronavirus.co.za</a></p>
            </div>
        </div>
    )
}

export default Covid19