import "./SimpleSlider.scss";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Tween } from "react-gsap";
import { FAT_BASTARD_API_IMAGE_PATH } from '../../utils/paths';

//https://github.com/akiran/react-slick

class Banner extends React.Component {
  renderContent() {
    return (
      <div className="sliderItem"
        style={{
          marginTop: '14px',
          background: `url(${FAT_BASTARD_API_IMAGE_PATH}images/September2019/oMIAkPv4fu7vT8y96pAL.jpg)`,
          backgroundPosition: "right center",
          backgroundSize: "cover"
        }}
      >
        <div className="ui container">
          <header className="SimpleSliderHeader leftaligned">
            <Tween
              from={{ y: -20, opacity: 0 }}
              delay=".2"
              duration=".4"
              ease="Linear.easeIn"
            >
              <div className="slider-product-name">
                <span>{this.props.breadcrumbs}</span>
              </div>
            </Tween>

            <Tween
              from={{ x: -20, opacity: 0 }}
              delay=".4"
              duration=".4"
              ease="Linear.easeIn"
            >
              <h2 className="banner-heading">{ReactHtmlParser(this.props.title)}</h2>
            </Tween>
            
            <Tween
              from={{ x: 30, opacity: 0 }}
              delay=".4"
              duration=".4"
              ease="Linear.easeIn"
            >
              <div className="slider-subtitle">
                {ReactHtmlParser(this.props.subtitle)}
                {/*  */}
              </div>
            </Tween>
          </header>
        </div>
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}

export default Banner;
