import React from "react";

export default function Video(props) {
  return (
    <div className="video-detail">
      <div className="ui embed">
        <iframe title="video player" src={props.url} />
      </div>
    </div>
  );
}
