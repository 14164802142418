import "./Content.scss";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import Loader from '../loader/Loader'
import { Tween } from "react-gsap";

export default function Content(props) {
  const classList = [
    'content-subtitle',
    props.fullWidth ? 'fullWidth' : '',
    props.centered ? 'centered' : '',
    props.disableFlex ? 'disableFlex' : '',
  ];

  const renderDefault = () => {
    if(!props.item){
      return <Loader />
    }

    return (
      <header>
         <h2 className="content-title">{ReactHtmlParser(props.item.title)}</h2>
        <div className="content-devider" />
        <div className={`${classList.join(' ')}`}>
         {ReactHtmlParser(props.item.subtitle)}
        </div>
      </header>
    )
  }

  const renderAnimated = () => {
    if(!props.item){
      return <div>Loading</div>
    }

    return (
      <header>       

        <Tween from={{ y: -20, opacity: 0 }} delay=".5" duration=".5">
          <h2 className="content-title"> {ReactHtmlParser(props.item.title)}</h2>
        </Tween>

        <Tween from={{ x: -50, opacity: 0 }} delay=".3" duration=".5">
          <div className="content-devider" />
        </Tween>

        <Tween from={{ y: 20, opacity: 0 }} delay=".7"staggar={0.3}duration=".5">
          <div className={`${classList.join(' ')}`}>
            {ReactHtmlParser(props.item.subtitle)}
          </div>
        </Tween>
      </header>
    )
  }

  return (
    <div className="content">
      {props.animated ? renderAnimated() : renderDefault()}
    </div>
  );
}
