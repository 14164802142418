import React, { Component } from "react";
import Iframe from 'react-iframe';
import './WhereToBuy.scss'

class WhereToBuy extends Component {

  render() {

    return (
      <div className="where_to_buy">
        <Iframe 
            src="https://vinimark.co.za/where-to-buy-iframe?user=fatbastard&producer=Fat%20Bastard"
            allow="geolocation"
            width={window.innerWidth}
            name="myiFrame"
            height="700px"
            id="myId"
            className="myClassname"
            display="initial"
            position="relative"
            allowfullscreen
        />

        {/* className="wtb"
                ref={this.wtb}
                allow="geolocation"
                id="wtb-widget"
                src="https://vinimark-stage.optimalonline.co.za/where-to-buy-iframe?user=fatbastard&producer=FAT bastard"
                name="myiFrame"
                scrolling="no"
                frameborder="1"
                marginheight="0px"
                marginwidth="0px"
                width="100%"
                allowfullscreen */}
      </div>
    );
  }
}

export default WhereToBuy;
