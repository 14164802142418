import React from 'react'
import ButtonArrow from '../buttons/ButtonArrow'
import ReactHtmlParser from "react-html-parser";

export default function VibCard (props) {
  return (
      <div className="vib-card" style={{ 
        background: `url(resources/img/${props.image})`,
        backgroundPosition: 'right',
        backgroundSize: 'cover',
      }}>
      
      <h3>{props.title}</h3>
      <div className="vib-devider "></div>
      <p style={{ textShadow: 'rgba(0, 0, 0, 0.38) 1px 1px 1px', maxWidth: '70%' }}>{ReactHtmlParser(props.subtitle)}</p>
      
      <ButtonArrow color="purple" toUrl={props.slug} title="next"/>
    </div>
    )
}

