import "./SocialIcons.scss";
import React from "react";
import SocialIconList from './SocialIconList';

export default function SocialIcons(props) {
  return (
    <div className="ui container">
      <div className="social-icons">
        {!props.onlyIcons &&
          <div className="social-icon-devider" />
        }
        
          <SocialIconList />
          
        {!props.onlyIcons &&
          <div className="social-icon-devider" />
        }
      </div>
    </div>
  );
}
