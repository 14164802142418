import "./Success.scss";
import React from "react";
import Button from "../buttons/Button";
import Gallery from "../gallery/Gallery";

export default function Success() {
  return (
    <React.Fragment>
      <div className="ui container">
        <div className="form-success">
          <h2 style={{ textAlign: "center" }}>
            THANK YOU <br /> FOR YOUR SUBMISSION
          </h2>
        </div>

        <div className="vib-restarurant-box">
          <div className="vib-r-content">
            <div className="content-box">
              <h2 className="title">VIB RESTAURANTS</h2>
              <p className="live_large" style={{ padding: "0 25px 0 0" }}>
                To <span className="gold_bold">LIVE LARGE</span>, you need to
                gallop to your local watering hole and take a chunk-size bite
                out of life. We unreservedly encourage you to try one of our VIB
                restaurants. These restaurants have been handpicked for their
                incomparable service, delectable food, and for being the perfect
                locale to enjoy a bottle or 2 of{" "}
                <span className="gold_bold">
                  FAT <i>bastard</i>
                </span>{" "}
                by the hearty mouthful.
              </p>
              <Button
                color="purple"
                toUrl="/restaurants"
                title="VIEW RESTAURANTS"
              />
            </div>
            <div className="content-box">
              <img
                src="../../resources/img/b1989d3bb6c57d06d9a8870a500fc936@2x.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <Gallery pageId={1} />
    </React.Fragment>
  );
}
