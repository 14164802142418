import { FETCH_PAGE_GALLERY } from "../actions/types";

const INITIAL_STATE = {
  images: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PAGE_GALLERY:
        return action.payload;
  
    default:
      return state;
  }
};
