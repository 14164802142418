import React from 'react'
import { Link } from "react-router-dom";

export default function Brand(props) {
  const renderBrandImage = () => {
    if(props.color === 'dark'){
      return  <img src="../../resources/img/Fat Bastard Logo White@2x.png" alt="" />
    }

    if(props.color === 'light'){
      return  <img src="../../resources/img/Fat Bastard@2x.png" alt="" />
    }
  }
  return (
    <div>
      <div className="brand ">
          <Link to="/">
            {renderBrandImage()}
          </Link>
        </div>
    </div>
  )
}