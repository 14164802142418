import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Header from "./header/Header";
import LandingPage from "./landing/LandingPage";
import TheFatBastards from "./the-fat-bastards/TheFatBastards";
import FatInspiration from "./fat-inspiration/FatInspiration";
import SocialFeed from "./social-feed/SocialFeed";
import RestaurantContainer from "./restaurants/RestaurantContainer";
// import RestaurantContainerV2 from "./restaurantsV2/RestaurantContainer";
import RestaurantContainerV3 from "./restaurantsV3/RestaurantContainer";
import RestaurantDetail from "./restaurants/RestaurantDetail";
import RecipeDetail from "./recipes/RecipeDetail";

import WineDetail from "./wines/WineDetail";
import Register from "./register/Register";
import ContactUs from "./contact-us/ContactUs";
import AgeGate from "./age-gate/AgeGate";
import Success from "./forms/Success";
import Footer from "./footer/Footer";
import ScrollToTop from "./ScrollToTop";
import ScrollToTopButton from "./ScrollToTopButton";
import tandc from "./tandc";
import NotFoundPage from "./404NotFound";
import WhereToBuy from './wines/WhereToBuy';
// import Disclaimer from './Popups/Disclaimer'

const App = () => {

  // if(
  //   window.location.pathname != '/fathersday' && 
  //   window.location.pathname != '/fathersday/thankyou'
  // ){

    return (
      <React.Fragment>
        <BrowserRouter>
          <ScrollToTop>
            <AgeGate />
            {/* <Disclaimer /> */}
            <Header />
            <Switch>
              {/*  old link redirects  */}
              <Redirect
                from="/pages/the_fat_bastards.php"
                to="/the-fat-bastards"
              />
              <Redirect
                from="/pages/fbinspiration/inspiration.php"
                to="/fat-inspirations"
              />
              <Redirect from="/pages/meet_mr_b.php" to="/" />
              <Redirect from="/pages/live_large.php" to="/" />

              <Route path="/" exact component={LandingPage} />
              <Route path="/the-fat-bastards" exact component={TheFatBastards} />
              <Route
                path="/the-fat-bastards/:slug"
                exact
                component={WineDetail}
              />
              <Route path="/fat-inspirations" exact component={FatInspiration} />
              <Route
                path="/fat-inspirations/:id"
                exact
                component={RecipeDetail}
              />
              {/* <Route path="/vib/register" exact component={Register} /> */}
              <Route path="/restaurants" exact component={RestaurantContainer} />
              {/* <Route path="/restaurants" exact component={RestaurantContainerV3} /> */}
              {/* <Route path="/vib/restaurants" exact component={RestaurantContainerV2} />  */}
              <Route path="/vib/restaurants/v3" exact component={RestaurantContainerV3} />{/* new */}
              <Route path="/restaurants/:id" exact component={RestaurantDetail} />
              <Route path="/social-feed" exact component={SocialFeed} />
              <Route path="/contact-us" exact component={ContactUs} />
              <Route path="/thank-you" exact component={Success} />
              <Route path="/terms-and-conditions" exact component={tandc} />
              <Route path="/where-to-buy" exact component={WhereToBuy} />
              {/* <Route exact path="/fathersday/optout" component={CampaignObtOut} /> */}

              {/* campaign */}
              {/* <Route path="/fathersday" component={ComingSoon} /> */}
              
              <Route path="/404" component={NotFoundPage} />
              <Redirect to="/404" />
              
            </Switch>
            <ScrollToTopButton />
            <Footer />
          </ScrollToTop>
        </BrowserRouter>
      </React.Fragment>
    );

  //}

  // return (
  //   <React.Fragment>
  //     <BrowserRouter>
  //       <ScrollToTop>
  //         <AgeGate />
  //         {/* <Disclaimer /> */}
  //         <Switch>

  //           {/* campaign */}
  //           <Route exact path="/fathersday" component={Campaign} />
  //           <Route exact path="/fathersday/thankyou" component={CampaignThankyou} />            
            
  //         </Switch>
  //         <ScrollToTopButton />
  //       </ScrollToTop>
  //     </BrowserRouter>
  //   </React.Fragment>
  // );
  
};

const mapStateToProps = state => {
  return { isVerified: state.isVerified };
};

export default connect(mapStateToProps)(App);
