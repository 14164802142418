import "./Button.scss";
import React from "react";

import { Link } from "react-router-dom";
import { Tween } from "react-gsap";

export default function Button(props) {
  const classList = [
    props.centered ? 'centered' : '',
  ];

  return (
    <div className={`${classList}`}>
      <Tween from={{ y: 20, opacity: 0 }} duration=".7" delay=".7" ease="Circ.easeOut">
        <Link to={props.toUrl} className={`btn ${props.color}`}>
          {props.title}
        </Link>
      </Tween>
    </div>
  );
}
