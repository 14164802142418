import "./AgeGate.scss";
import React, { Component } from "react";
import { connect } from "react-redux";
import Brand from "../brand/Brand";
import Form from "./Form";
import { verifyAge } from "../../actions";
import Covid19 from '../disclaimer/Covid19'

class AgeGate extends Component {
  componentDidMount() {
    const verified = localStorage.getItem("isVerified");

    !verified && this.props.verifyAge(verified);
  }

  onAgeConfirm = ({ verified }) => {
    verified && localStorage.setItem("isVerified", true);

    this.props.verifyAge(verified);
  };

  render() {
    return (
      <div>
        {!this.props.isVerified && (
          <div className="age-gate-overlay">
            <div className="age-gate-container">
              <div className="age-gate">
                <div className="age-gate-box age-gate-left">
                  <Brand color="light" />

                  <h3>
                    YOU MUST BE OF LEGAL DRINKING <br /> AGE TO VISIT THIS
                    WEBSITE:
                  </h3>

                  <div className="age-gate-confirm">
                    <Form onAgeConfirm={this.onAgeConfirm} />
                  </div>
                </div>

                <div className="age-gate-box age-gate-right">
                  <p>
                    To enjoy FAT <i>bastard</i>, you need to be able to <br />{" "}
                    <span>LIVE LIKE ONE</span>.
                  </p>

                  <p>
                    Living <span>LARGE</span> requires a salubrious take on the
                    good life.{" "}
                  </p>

                  <p>
                    It comes with <span>EXPERIENCE</span> so if you're not there
                    yet, keep at it
                  </p>

                  <p className="age-gate-link">
                    To view the FAT bastard Website for Europe and Asia click
                    <a
                      href="http://international.fatbastard.com/"
                      target="_blank"
                       rel="noopener noreferrer"
                    >
                      here
                    </a>
                    .{" "}
                  </p>

                  <p className="age-gate-link">
                    To view the FAT bastard Website for the US and Canada click{" "}
                    <a href="http://fatbastard.com/?country=us" target="_blank"  rel="noopener noreferrer">
                      here
                    </a>
                    .{" "}
                  </p>
                  <Covid19 className="hr_covid-19-agegate" />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { isVerified: state.isVerified };
};

export default connect(
  mapStateToProps,
  { verifyAge }
)(AgeGate);
