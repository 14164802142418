import React from "react";

export default function tandc() {
  return (
    <>
    <br /><br /><br /><br />



    <div className="container fb_tandc" style={{ marginBottom:"100px" }}>
      <h2 align="center" class="style6">
        <strong style={{fontWeight: 'bold'}}>WELCOME TO FAT <i>bastard</i>. </strong>
      </h2>
      <p align="center" class="style6">
        <strong>
          WE SUPPORT RESPONSIBLE DRINKING AND OUR WINES ARE NOT FOR SALE TO
          PERSONS UNDER THE AGE OF 18.
        </strong>
      </p>


      <p align="center" class="style6"><strong style={{fontWeight: 'bold'}}>“25th Birthday Giveaway” – Online Competition</strong></p>
      <ol>
        <li>The competition is open to all residents of South Africa who are aged 18 years or older.</li>
        <li>The Promoter is Robertson Winery (Pty) Ltd, hereinafter referred to as "the Promoter".</li>
        <li>Excluded from entering this competition are employees, directors, members, consultants and partners of the advertising agency or agents of the Promoter, and their spouses, life partners, immediate family, business partners or associates.</li>
        <li>There is one grand prize that can be won by one winner. The Grand Pize is comprised of:<br />
          <ul>
            <li>25 bottles of FAT bastard Wine </li>
            <li>SnoMaster® 46 Bottle Dual Zone Wine Chiller </li>
          </ul>
        </li>
        <li>The total value of the Grand Prize is R13 500.00</li>
        <li>The competition runs from 9 am on Monday, 03 October 2022 to 5 pm on Monday, 31 October 2022.</li>
        <li>To enter participants must purchase at least one case of FAT bastard wines from our online shop:  https://shop.fatbastardwine.co.za/.</li>
        <li>Users may enter the competition as many times as they like – Each case bought counts as an extra entry to the competition.</li>
        <li>However, if a user has previously won a Robertson Winery or FAT bastard promotion in 2019, 2020, 2021 or 2022 they will not be eligible to win the Grand Prize.</li>
        <li>The Grand Prize cannot be transferred or exchanged for its cash value. The prize shown in the promotional material is not an actual representation of the prize.</li>
        <li>One Grand Prize winner will be randomly selected (using an automated system) and announced over our social media channels (Facebook, Instagram).</li>
        <li>The Winner will need to provide proof of identity in order to redeem the prize.</li>
        <li>The Winner needs to supply their daytime delivery address.</li>
        <li>The Promoter will make every reasonable attempt to contact the Winner. Should we be unable to contact the Winner we reserve the right to draw a new Winner.</li>
        <li>By entering the competition and/or accepting the prize, the Winner hereby indemnifies, releases and holds harmless Robertson Winery (Pty) Ltd. and the Promoter and other, from and against any actions, claims, liability for injury, loss, damage of any kind resulting from the competition and/or prize.</li>
        <li>The Promoter’s decision is final, and no correspondence will be entered into.</li>
        <li>The Promoter reserves the right to change or cancel the promotion and/or prize for whatever reason they see fit.</li>
        <li>By entering this competition, you agree to give the Promoter and its group of companies permission to keep all material and information submitted as part of your entry, on electronic or hard copy databases and filing systems for the purpose of the above-mentioned competition and by entering this competition, you agree to the Promoter transferring your data to our promotional partners to enable the awarding of the winning prize.</li>
        <li>The Promoter will obtain written consent from the Winner should the Promoter wish to use the names, photographs, and entries submitted by the relevant competition Winner, for the purposes of unpaid publicity relating to the promotion, by the Promoter.</li>
        <li>Entry instructions are deemed to form part of the Terms and Conditions and by entering this competition all participants will be deemed to have accepted and be bound by the Terms and Conditions. Please retain a copy for your information.</li>
      </ol>
      <p>PROMOTER: Robertson Winery (Pty) Ltd</p>
    </div>
    </>
  );
}
