import { VERIFY_AGE } from "../actions/types";

const INITIAL_STATE = {
  ageVerified: null,

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VERIFY_AGE:
      return action.payload;

    default:
      return state;
  }
};
