import React from 'react'
import GalleryList from './GalleryList'
import GallerySlider from './GallerySlider'

export default function Gallery(props) {
  const renderGalleryComponent = () => {
    if(window.innerWidth > 568){
      return <GalleryList pageId={props.pageId}/>
    } else {
      return <GallerySlider pageId={props.pageId}/>
    }
  }

  return (
    <div>
      {renderGalleryComponent()}
    </div>
  )
}
