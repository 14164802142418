import './SocialBanner.scss'

import React from 'react'
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

export default function SocialBanner() {
  return (
    <div className="social-banner">
      <div className="social-banner-image">
        <img src="../../resources/img/social_banner/1.png" alt="" />
      </div>
      <div className="social-banner-image">
        <img src="../../resources/img/social_banner/2.png" alt="" />
      </div>

      <Link to="social-feed" className="view-social-button">
        <div className="social-link-container">
          <div className="social-link-copy">
          VIEW OUR  FAT SOCIAL FEED
          </div>
          <div className="social-link-icon">
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
        </div>
      </Link>
     
      <div className="social-banner-image">
        <img src="../../resources/img/social_banner/3.png" alt="" />
      </div>
      <div className="social-banner-image">
        <img src="../../resources/img/social_banner/4.png" alt="" />
      </div>
    </div>
  )
}
