import "./NavDropDown.scss";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class NavDropDown extends Component {
  constructor(props) {
    super(props);
    this.dropDown = React.createRef();
    this.menu = React.createRef();
  };
  
  handleOutsideClick = (e) => {
    this.toggleMenu();
  }

  toggleMenu = () => {
    if (this.dropDown.current.classList.contains("active")) {
      this.dropDown.current.classList.remove("active", "visible");
      this.menu.current.classList.remove("active", "visible");
      this.menu.current.classList.add("hidden");
      document.removeEventListener('click', this.handleOutsideClick, false)
    } else {
      document.addEventListener('click', this.handleOutsideClick, false)
      this.dropDown.current.classList.add("active", "visible");
      this.menu.current.classList.add("transition", "visible");
      this.menu.current.classList.remove("hidden");
    }
  };

  renderItems = (items) => {
 
    return items.map(item => {
      return (
        <div className="item" data-value="1" key={item.name}>
         <NavLink
            onClick={this.props.toggleMenu}
            to={item.link}
            exact
            className="nav-NavLink p-t-5 p-b-0 "
          >
            {item.name}
          </NavLink>
        </div>
      )
    })
  }

  render() {
    const { items } = this.props;

    return (
      <li className="dropdown-container">
        <div className="dropdown-trigger" onClick={this.toggleMenu} ref={this.dropDown}>
          <div
            className="nav-NavLink p-t-5 p-b-0"
          >
            VIB
          </div>
        </div>
        <div ref={this.menu} className='dropdown-nav transition hidden'>
          {this.renderItems(items)}
        </div>
      </li>
    );
  }
}

export default NavDropDown;
