import "./Restaurant.scss";
import React, { Component } from "react";
import { connect } from "react-redux";

import Gallery from "../gallery/Gallery";
import Banner from "../slider/Banner";
import Content from "../content/Content";

import Restaurants from "../restaurants/Restaurants";

class RestaurantContainer extends Component {
  renderContent() {
    return (
      <div className="fat-inspiration">
        {/* <div style={{ height: "320px" }}> */}
          <Banner title="VIB RESTAURANTS" breadcrumbs="HOME / VIB RESTAURANTS" subtitle={`
            <p>To <strong style="color: rgb(214, 176, 82)">LIVE LARGE</strong>, you need to gallop to your local watering hole and take a chunk-size bite out of life. We unreservedly encourage you to try one of our VIB restaurants. These restaurants have been handpicked for their incomparable service, delectable food, and for being the perfect locale to enjoy a bottle or 2 of <strong style="color: rgb(214, 176, 82)">FAT <em>bastard</em></strong> by the hearty mouthful.</p>
          `}/>
        {/* </div> */}

        <div className="ui container">
          <Content
            disableFlex
            fullWidth
            centered
            animated
            item={{
              title: "EXQUISITE FOOD",
              subtitle: ""
            }}
          />

          <Restaurants showHeader="true" />
        </div>
        <Gallery pageId={7} />
      </div>
    );
  }

  render() {
    if (!this.props.isVerified) {
      return <div>loading</div>;
    }

    return <div>{this.renderContent()}</div>;
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified
  };
};

export default connect(mapStateToProps)(RestaurantContainer);
