import React from 'react';
import './Pagination.scss';
import { Link, useLocation  } from 'react-router-dom';

export default function Pagination({ data }) {
  if (!data) {
    
    return <></>;
  }

  const createQueryString = (page) => {
    const url = new URL(window.location.href);
    url.searchParams.set('page', page);
    return url.search;  
  };

  const renderButtons = () => {
    const totalPages = data.last_page;
    const currentPage = data.current_page;
    const buttonsToShow = 5; // Number of pages to display around the current page
    const buttonArray = [];

    // Always show the first page
    buttonArray.push(
      <Link 
        to={`/vib/restaurants${createQueryString(1)}`}
        className={`_item ${currentPage === 1 ? '_active' : ''}`}
        key={1}>
        1
      </Link>
    );

    // If current page is far from the first page, add '...'
    if (currentPage > buttonsToShow) {
      buttonArray.push(<div className='_item' key="start-ellipsis">...</div>);
    }

    // Calculate the range of page numbers to show around the current page
    let startPage = Math.max(2, currentPage - 2);
    let endPage = Math.min(totalPages - 1, currentPage + 2);

    // Display page numbers within the range
    for (let i = startPage; i <= endPage; i++) {
      
      buttonArray.push(
        <Link to={`/vib/restaurants${createQueryString(i)}`}
            className={`_item ${i === currentPage ? '_active' : ''}`} 
            key={i}
        >
          {i}
        </Link>
      );
    }

    // If current page is far from the last page, add '...'
    if (currentPage < totalPages - (buttonsToShow - 1)) {
      buttonArray.push(<div className='_item' key="end-ellipsis">...</div>);
    }

    // Always show the last page
    if (totalPages > 1) {
      buttonArray.push(
        <Link 
            to={`/vib/restaurants${createQueryString(totalPages)}`} 
            // className='_item' 
            className={`_item ${totalPages === currentPage ? '_active' : ''}`}
            key={totalPages}>
          {totalPages}
        </Link>
      );
    }

    return buttonArray;
  };

  return (
    <div className='_pagination-container'>
      <div className='_pagination'>
        {data.total > data.per_page && (
          <>
          {data.current_page === 1 ? (
            <div className='_item _prev'>
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" fill="#B2B2B2" /></svg>
                <span>Previous</span>
            </div>
          ) : (
          <Link to={`/vib/restaurants${createQueryString(data.current_page - 1)}`} className='_item _prev _enabled'>
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z"/></svg>
                <span>Previous</span>
          </Link>
          )}
          {renderButtons()}
          {data.current_page === data.last_page ? (
            <div className='_item _next'>
                <span>Next</span>
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" fill="#B2B2B2" /></svg>
            </div>
          ) : (
          <Link to={`/vib/restaurants${createQueryString(data.current_page + 1)}`} className='_item _next _enabled'>
                <span>Next</span>
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg>
          </Link>
          )}
          </>
        )}
        
      </div>
    </div>
  );
}
